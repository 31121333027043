<template>
        <vs-sidebar click-not-close position-right parent="#app" class="apps-sidebar" spacer v-model="localActiveSidebar">
            <div class="mt-6 flex items-center justify-between px-6" slot="header">
                <h4 class="text-white"> {{ this.$t('Editar Aliado') }} </h4>
                <feather-icon icon="XIcon" @click.stop="localActiveSidebar = false" class="cursor-pointer"></feather-icon>
            </div>


            <VuePerfectScrollbar class="scroll-area--data-list-add-new px-2">
                <form @submit.prevent="attempSaveAllied()" id="appForm">
            
                    <div class="vx-row">
                        <!-- imagen -->
                        <div class="vx-col w-full">
                            <vs-images :hover="'blur'">
                                <vs-image :src="app.url_image"/>
                            </vs-images>
                        </div>

                        <!-- nombre -->
                        <div class="vx-col w-full">
                            <vx-input-group>
                                <vs-input
                                    :label="$t('Nombre')"
                                    name="name"
                                    v-model="app.name"
                                    :danger="!!validate.nameError"
                                    val-icon-danger="clear"
                                    autofocus
                                    required
                                />
                            </vx-input-group>
                            <p class="text-danger text-sm" v-if="!!validate.nameError">{{ validate.nameError }}</p>
                        </div>

                        <!-- descripción -->
                        <div class="vx-col w-full mt-5">
                            <vx-input-group>
                                <vs-textarea
                                    :label="$t('Descripción')"
                                    name="description"
                                    v-model="app.description"
                                    :danger="!!validate.descriptionError"
                                    val-icon-danger="clear"
                                    required
                                />
                            </vx-input-group>
                            <p class="text-danger text-sm" v-if="!!validate.descriptionError">{{ validate.descriptionError }}</p>
                        </div>

                        <!-- email -->
                        <div class="vx-col w-full mt-5">
                            <vx-input-group>
                                <vs-input
                                    :label="$t('Correo electrónico')"
                                    name="email"
                                    type="email"
                                    v-model="app.email"
                                    :danger="!!validate.emailError"
                                    val-icon-danger="clear"
                                    required
                                />
                            </vx-input-group>
                            <p class="text-danger text-sm" v-if="!!validate.emailError">{{ validate.emailError }}</p>
                        </div>

                        <!-- url -->
                        <div class="vx-col w-full mt-5">
                            <vx-input-group>
                                <vs-input
                                    :label="$t('Dirección web')"
                                    name="url"
                                    v-model="app.url"
                                    :danger="!!validate.urlError"
                                    val-icon-danger="clear"
                                    required
                                />
                            </vx-input-group>
                            <p class="text-danger text-sm" v-if="!!validate.urlError">{{ validate.urlError }}</p>
                        </div>

                        <!-- metodo de pago -->
                        <div class="vx-col w-full mt-5">
                            <vs-select :label="$t('Método de pago')" v-model="app.payment_method" multiple :placeholder="$t('Seleccione método de pago disponible')" class="w-full">
                                <!-- eslint-disable -->
                                <vs-select-item :key="index" :value="item" :text="$t(item)" v-for="item,index in payment_methods" />
                            </vs-select>
                        </div>

                    <!-- imagen -->
                    <div class="vx-col w-full mt-5 mb-10">
                        <input type="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" class="w-px h-px opacity-0 overflow-hidden absolute" ref="file" accept="image/png, image/jpeg" @change="onChange()" />

                        <h3>{{ this.$t('Cambiar foto del aliado') }}</h3>
                        <span>{{ this.$t('Recuerda las características mínimas de la imagen de marca') }}</span>

                        <!-- si no hay imagen lista para cargar -->
                        <div
                            v-if="!image.file"
                            class="border-2 border-dashed text-center pt-5 pb-8 mt-5 cursor-pointer"
                            @click="clickFileButton()"
                            @dragover="dragover"
                            @dragleave="dragleave"
                            @drop="drop"
                        >
                            <img :src="require(`@/assets/icons/UploadFileIcon.svg`)" width="32px" class="inline-block align-middle"/><br/><br/>
                            <span class="text-gray">{{ this.$t('Suelta tu imagen aquí, ó') }}</span>&nbsp;
                            <vs-button color="#daf7fd" text-color="#1abfda" class="rounded-full small">{{ this.$t('Busca Archivo') }}</vs-button>
                        </div>

                        <div v-if="!image.file" class="vx-row mt-2">
                            <div class="vx-col w-1/2">
                                <span class="text-gray">{{ this.$t('Formatos Soportados: JPG, PNG') }}</span>
                            </div>

                            <div class="vx-col w-1/2">
                                <span class="text-gray float-right">{{ this.$t('Tamaño Máximo: 5Mb') }}</span>
                            </div>
                        </div>

                        <vx-card v-else class="mt-2">
                            <div class="vx-row">
                                <div class="vx-col w-auto">
                                    <img :src="require(`@/assets/icons/JpgFileIcon.svg`)" width="60px" class="inline-block align-middle"/><br/><br/>
                                </div>

                                <div class="vx-col w-11/12">
                                    <feather-icon icon='XIcon' class="float-right cursor-pointer" @click="image.file = null" />
                                    <h4>{{ image.file.name }}</h4>
                                    <span>{{ this.$t('Subido') }} {{ image.progress }}%</span>
                                    <vs-progress :height="12" :percent="image.progress" :color="image.error ? 'danger' : 'success'"></vs-progress>
                                </div>
                            </div>
                        </vx-card>
                    </div>
                </div>

            </form>

            </VuePerfectScrollbar>
            
            <div class=" items-center p-6" slot="footer">
                <button type="button" class="vs-component vs-button vs-button-danger vs-button-border" @click="localActiveSidebar = false">
                    {{ this.$t('Cancelar') }}
                </button>

                <button type="submit" form="appForm" class="vs-component vs-button vs-button-info vs-button-filled float-right">
                    {{ this.$t('Guardar') }}
                </button>
            </div>
        </vs-sidebar>
</template>

<script lang="ts">
import Vue                 from 'vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import { Validation }      from '../../../plugins/validation.js';

export default Vue.extend({
    props: {
        sidebar: {
            required: true,
            type: Boolean,
        },

        data: { required: true },

    },

    data() {
        return {
            validate     : new Validation(),
            validFormats : ['jpg', 'jpeg', 'png'],
            image        : {
                progress : 0,
                error    : '',
                file     : null,
            },
        }
    },

    watch: {
        'data.id'( val ) {
            if( val == 0 ) return;
            this.$vs.loading();
            this.$store.dispatch( 'applications/getApplication', val )
                .then( () => this.$vs.loading.close() );
        },
        'app.name'(val) {
            this.validate.validateName( val );
        },
        'app.description'(val) {
            this.validate.validateDescription( val );
        },
        'app.email'(val) {
            this.validate.validateEmail( val );
        },
        'app.url'(val) {
            this.validate.validateUrl( val );
        },
    },

    methods: {
        attempSaveAllied() {
            const isValidName = this.validate.validateName( this.app.name );
            const isValidDesc = this.validate.validateDescription( this.app.description );
            const isValidMail = this.validate.validateEmail( this.app.email );
            const isValidURL  = this.validate.validateUrl( this.app.url );

            if( !isValidName || !isValidDesc || !isValidMail || !isValidURL ) {
                this.$vs.notify({
                    title    : this.$t('Verifique los campos antes de continuar'),
                    iconPack : 'feather',
                    icon     : 'icon-alert-circle',
                    color    : 'warning',
                    time     : 7100
                });
                return;
            }

            if( this.image.file ) {
                this.$vs.loading();
                this.$store.dispatch( 'applications/uploadAlliedImage', this.image )
                    .then( () => {
                        this.$vs.loading.close();
                        this.saveAllied();
                    }).catch( () => {
                        this.$vs.loading.close();
                    });

            } else {
                this.saveAllied();
            }
        },

        saveAllied() {
            this.$vs.loading();
            this.$store.dispatch( 'applications/saveAllied', this.app )
                .then( res => {
                    this.$vs.loading.close();
                    if( res ) this.localActiveSidebar = false;
                });
        },

        onChange() {
            const filename      = this.$refs.file.files[0].name;
            const filesize      = this.$refs.file.files[0].size;
            const filesizeMb    = Math.floor( filesize/1000000 );
            const ext           = filename.substr(filename.lastIndexOf('.') + 1).toLowerCase();
            const isValidFormat = this.validFormats.filter(format => format === ext);

            if( filesizeMb > 2 ) {
                this.$vs.notify({
                    title: this.$t('El límite de tamaño es de 2Mb'),
                    text: this.$t('El límite de tamaño es de 2Mb'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning',
                    time: 7000,
                });
                return;
            }

            if( isValidFormat.length === 0 ) {                
                return this.$vs.notify({
                    title: this.$t('Formato Incorrecto'),
                    text: this.$t('Cargue archivos .jpg ó .png solamente'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning'
                });
            }
            
            this.image.file = this.$refs.file.files[0];
        },

        clickFileButton() {
            this.$refs.file.click();
        },

        dragover( ev ) {
            ev.preventDefault();
            
            if (!ev.currentTarget.classList.contains('bg-primary')) {
                ev.currentTarget.classList.remove('bg-gray-100');
                ev.currentTarget.classList.add('bg-primary');
            }
        },

        dragleave( ev ) {
            ev.currentTarget.classList.remove('bg-primary');
        },

        drop( ev ) {
            ev.preventDefault();

            this.$refs.file.files = ev.dataTransfer.files;
            this.onChange();
            ev.currentTarget.classList.remove('bg-primary');
        },
    },

    computed: {
        localActiveSidebar: {
            get: function() {
                return this.sidebar;
            },
            set: function() {                
                this.$emit( 'closeSidebar', false );
                this.$store.dispatch( 'applications/getAllApplications' );
                this.$store.commit( 'applications/CLEAR_APP_DATA' );
                this.image.file = null;
                this.image.progress = 0;
            }
        },

        app() { // app proxy
            const store = this.$store;
            const app = store.state.applications.app;
            let appProxy = new Proxy( app, {
                set (obj, key, value) {
                    store.commit('applications/SET_APP', {
                        ...obj,
                        [key]: value,
                    });    
                    return true
                }
            });
            return appProxy;
        },

        payment_methods() {
            return this.$store.state.applications.payment_methods;
        }

    },

    components: {
        VuePerfectScrollbar,
    }
});
</script>

<style lang="scss" scoped>
.apps-sidebar {
    ::v-deep .vs-sidebar--background {
        z-index: 52010;
    }
    
    ::v-deep .vs-sidebar {
        position: fixed;
        z-index: 52010;
        width: 350px;
        max-width: 90vw;
        .vs-sidebar--header {
            background: #841910;
            color: #FFF;
        }
    }
}

.scroll-area--data-list-add-new {
    height: 79vh;
}

textarea {
    height: 80px;
    line-height: 150%;
    resize:vertical;
    box-sizing: padding-box;
    font-size: 1rem;
    border-radius: 6px;
    box-shadow: 2px 2px 8px rgba(black, .3);
    border: 2;
}
</style>
