<template>
    <div>
        <vs-button class="rounded-full" to="/app/applications/create">
            {{ this.$t('Crear aliado') }}
        </vs-button>

        <vx-card class="mt-10">
            <h3 class="text-info">{{ this.$t('Listado de Aliados disponibles') }}</h3>

            <div class="flex justify-end w-full">
                <!-- eslint-disable -->
                <vs-select :label="this.$t('Items por página')" v-model="maxItems" class="w-24">
                    <vs-select-item :key="index" :value="item" :text="item" v-for="item,index in maxItemsVals" />
                </vs-select>
            </div>

            <vs-table :data="apps" search :max-items="maxItems" pagination :noDataText=" $t( 'Datos no disponibles' ) ">

                <template slot="thead">
                    <vs-th></vs-th> <!-- imagen -->
                    <vs-th>{{ this.$t('id') }}</vs-th>
                    <vs-th>{{ this.$t('Nombre') }}</vs-th>
                    <vs-th>{{ this.$t('URL') }}</vs-th>
                    <vs-th>{{ this.$t('Estado') }}</vs-th>
                    <vs-th></vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr :data="tr" v-bind:key="indextr" v-for="(tr, indextr) in data">
                        <!-- Imagen -->
                        <vs-td :data="data[indextr].url_image">
                            <img
                                :src="data[indextr].url_image"
                                :alt="data[indextr].name"
                                width="77px"
                                height="77px"
                                class="rounded-lg image-shadow"
                            />
                        </vs-td>

                        <!-- id -->
                        <vs-td :data="data[indextr].id">
                            <span class="text-info"> {{ data[indextr].id }} </span>
                        </vs-td>

                        <!-- nombre -->
                        <vs-td :data="data[indextr].name">
                            <span>{{ data[indextr].name }}</span>
                        </vs-td>

                        <!-- URL -->
                        <vs-td :data="data[indextr].url">
                            <a v-if="data[indextr].url" :href="data[indextr].url" target="_blank" class="text-info font-bold text-lg">
                                <feather-icon icon="ExternalLinkIcon"/>
                                {{ data[indextr].url | truncate(30) }}
                                ...
                            </a>
                        </vs-td>

                        <!-- estado -->
                        <vs-td :data="data[indextr].status">
                            <vs-switch color="success" :value="data[indextr].status" @click="changeStatus( data[indextr].id )"/>
                        </vs-td>

                        <!-- acciones -->
                        <vs-td :data="data[indextr].id">
                            <vs-button size="small" color="transparent" @click.stop="editApp( data[indextr].id )">
                                <img :src="require(`@/assets/icons/EditGrayIcon.svg`)" width="15px" />
                            </vs-button>
                            
                            <!-- boton eliminar -->
                            <!-- <vs-button size="small" color="transparent" @click.stop="activeDeleteDialog( data[indextr].id )">
                                <img :src="require(`@/assets/icons/DeleteGrayIcon.svg`)" width="15px" />
                            </vs-button> -->
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vx-card>

        <EditAppSidebar :sidebar="sidebar" :data="appSelected" @closeSidebar="closeSidebar"/>

        <!-- modal eliminar -->
        <vs-prompt
            @accept="deleteApp()"
            @close="closeDeleteDialog()"
            :active.sync="activePrompt"
            buttons-hidden
        >
            <div class="w-full">
                <vs-button  class="float-right" @click="closeDeleteDialog()" color="transparent" text-color="#666">
                    <feather-icon icon='XIcon'  />
                </vs-button>
            </div>
            
            <div class="w-full flex justify-center mb-5">
                <img :src="require(`@/assets/images/elements/Trash.svg`)" alt="Delete Icon" width="128px" />
            </div>
            
            <div class="text-center text-gray-400">
                <h3 class="mb-2">
                    {{ this.$t('Eliminar Aliado') }}
                </h3>
                <p>
                    <span>{{ this.$t('¿Estás seguro que deseas eliminar definitivamente el aliado seleccionado?') }}</span>
                </p>

                <p><vs-button class="mt-5 rounded-full" @click="deleteApp()">{{ this.$t('Eliminar Definitivamente') }}</vs-button></p>
                <p><vs-button  class="mt-2 text-primary" @click="closeDeleteDialog()" color="#FFF">{{ this.$t('Cancelar') }}</vs-button></p>
            </div>
        </vs-prompt>

    </div>
</template>

<script lang="ts">
import Vue from 'vue';

import EditAppSidebar from './EditAppSidebar.vue';

export default Vue.extend({
    data() {
        return {
            sidebar      : false,
            appSelected  : { id: 0 },
            activePrompt : false,
            maxItems     : 10,
            maxItemsVals : [5,10,15,20,25,30,35,40,45,50],
        }
    },
    
    created() {
        this.$vs.loading();
        this.$store.dispatch( 'updatePageTitle', {
            title: this.$t('Aliados'),
            subtitle: this.$t('Crea y administra aliados'),
        });

        Promise.all([
            this.$store.dispatch( 'applications/getAllApplications' ),
        ]).then( () => this.$vs.loading.close() );
    },

    methods: {
        editApp( app_id=0 ){
            this.sidebar = true;
            const app = this.apps.find( app => app.id === app_id );
            this.appSelected = app;
        },

        closeSidebar( val ) {
            this.sidebar     = val;
            this.appSelected = { id: 0 };
        },

        activeDeleteDialog( app_id=0 ) {
            this.activePrompt = true;
            this.appSelected = this.apps.find( app => app.id === app_id );
        },

        deleteApp() {
            this.$vs.loading();
            this.$store.dispatch( 'applications/deleteApplication', this.appSelected.id )
                .then( res => {
                    this.$vs.loading.close();
                    if( res ) this.closeDeleteDialog();
                })
        },

        closeDeleteDialog() {
            this.activePrompt = false;
            this.appSelected = { id: 0 };
        },

        async getImageFromFb() {
            // const filename = url.substring( url.lastIndexOf('/') + 1 );
            // const filenameCleaned = filename.substring( 0, filename.lastIndexOf('?') );
            // const filenameDecoded = decodeURIComponent( filenameCleaned );

            // const app = this.apps.find( app => app.id === id );
            // const appIndex = this.apps.indexOf( app );

            // const imageUrl = await this.$store.dispatch( 'getFileUrl', filenameDecoded );
            
            // this.$store.commit( 'applications/SET_APP_INDEX', {
            //     ...app,
            //     index     : appIndex,
            //     url_image : imageUrl,
            // });
        },

        changeStatus( app_id=0 ) {
            this.$vs.loading();
            this.$store.dispatch( 'applications/deleteApplication', app_id )
                .then( () => this.$vs.loading.close() );
        },
    },

    computed: {
        apps: {
            get() {
                return this.$store.state.applications.apps;
            },
        }
    },

    components: {
        EditAppSidebar,
    }

});
</script>
